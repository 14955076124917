<template>
  <div v-if="isLoading"><Loader /></div>
  <div class="py-4 container-fluid" v-else>
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="card main_card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <div class="row">
                  <div class="col-md-12 left">
                    <div class="card  mb-3">
                      <div class="card-header pb-0">
                        <div class="d-flex align-items-center">
                          <p class="mb-0 text-dark text-bold">
                            {{managerForm.manager_name}}
                          </p>
                        </div>
                      </div>
                      <div class="card-body">
                        <p class="text-uppercase text-sm">Property Manager Information</p>
                        <div class="row">
                          <div class="col-md-12">
                            <dl>
                              <dt>Name</dt>
                              <dd>{{managerForm.manager_name}}</dd>
                              <dt>Address</dt>
                              <dd>{{ managerForm.manager_address }}</dd>
                            </dl>
                          </div>
                        </div>

                        <hr class="horizontal dark" />
                        <p class="text-uppercase text-sm">Contact Information</p>
                        <div class="contant-info">
                          <dl>
                            <dt>Phone</dt>
                            <dd>{{ managerForm.manager_phone }}</dd>
                            <dt>Email</dt>
                            <dd>{{ managerForm.manager_email }}</dd>
                          </dl>
                        </div>

                        <hr class="horizontal dark" />
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-body sidebar">
                        <p class="text-uppercase text-sm">Manager More Information</p>
                        <dl>
                          <dt>Joined</dt>
                          <dd>{{ dateTime(managerForm.date_created) }}</dd>
                          <dt>Balanced Owed</dt>
                          <dd>4,500</dd>
                          <dt>Last Seen</dt>
                          <dd>{{ dateTime(managerForm.last_seen) }}</dd>
                          <dt><hr class="horizontal dark" /></dt>
                          <dd></dd>
                        </dl>
                        <p class="text-uppercase text-sm">Manager Login Stats</p>
                        <dl>
                          <dt>Current Month Logged In</dt>
                          <dd>{{ managerForm.login_entries_current_month }}</dd>
                          <dt>Previous Month Logged In</dt>
                          <dd>{{ managerForm.login_entries_previous_month }}</dd>
                          <dt><hr class="horizontal dark" /></dt>
                          <dd></dd>
                        </dl>
                        <p class="text-uppercase text-sm">Users Login Stats</p>
                        <dl>
                          <dt>Users Current Month Logged In</dt>
                          <dd>{{ managerForm.login_entries_current_month }}</dd>
                          <dt>Users Previous Month Logged In</dt>
                          <dd>{{ managerForm.login_entries_previous_month }}</dd>
                          <dt><hr class="horizontal dark" /></dt>
                          <dd></dd>
                        </dl>
                        <p class="text-uppercase text-sm">Tenants Login Stats</p>
                        <dl>
                          <dt>Tenants Current Month Logged In</dt>
                          <dd>{{ managerForm.login_entries_current_month }}</dd>
                          <dt>Tenants Previous Month Logged In</dt>
                          <dd>{{ managerForm.login_entries_previous_month }}</dd>
                          <dt><hr class="horizontal dark" /></dt>
                          <dd></dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <el-tabs v-model="activeName" tab-position="top" stretch="true" style="height: 50%">
                  <el-tab-pane name="manager">
                    <template #label>
                      <span class="custom-tabs-label">
                        <i class="fa fa-user p-3" aria-hidden="true"></i>
                        <span>Manager Details</span>
                      </span>
                    </template>
                    <div class="px-1">
                      <p class="text-uppercase text-sm">UPDATE MANAGER</p>
                      <hr class="horizontal dark" />
                      <el-form
                          ref="managerFormRef"
                          :model="managerForm"
                          label-width="auto"
                          class="demo-ruleForm"
                          size="large"
                          status-icon
                          :scroll-to-error="true"
                          label-position="top"
                          :hide-required-asterisk="true"
                      >
                        <div class="row">
                          <div class="col-md-6">
                            <el-form-item
                                label="Name"
                                prop="manager_name"
                            >
                              <el-input v-model="managerForm.manager_name" />
                            </el-form-item>
                            <el-form-item
                                label="Email"
                                prop="manager_email"
                            >
                              <el-input v-model="managerForm.manager_email" />
                            </el-form-item>
                            <el-form-item label="Phone Number" prop="manager_phone">
                              <el-input v-model="managerForm.manager_phone" />
                            </el-form-item>
                            <el-form-item
                                label="Address"
                                prop="manager_address"
                            >
                              <el-input v-model="managerForm.manager_address" />
                            </el-form-item>
                          </div>
                          <div class="col-md-6">
                            <el-form-item label="Billing Rate" prop="manager_billing_rate">
                              <el-input
                                  width="100%"
                                  v-model="managerForm.manager_billing_rate"
                                  :controls="false"
                              />
                            </el-form-item>
                            <el-form-item
                                label-width="40%"
                                label="Invoice VAT"
                                prop="invoice_vat"
                            >
                              <el-radio-group v-model="managerForm.invoice_vat">
                                <el-radio
                                    v-for="(invoice_vat, index) in [
                                      { value: 'active', label: 'Activated' },
                                      { value: 'inactive', label: 'Deactivate' },
                                    ]"
                                    :key="index"
                                    :label="invoice_vat.value"
                                    size="large"
                                    border
                                >{{ invoice_vat.label }}</el-radio
                                >
                              </el-radio-group>
                            </el-form-item>
                            <el-form-item
                                label-width="40%"
                                label="Cronjob Status"
                                prop="cronjob"
                            >
                              <el-radio-group v-model="managerForm.cronjob">
                                <el-radio
                                    v-for="(cronjob, index) in [
                                      { value: 'active', label: 'Activated' },
                                      { value: 'inactive', label: 'Deactivate' },
                                    ]"
                                    :key="index"
                                    :label="cronjob.value"
                                    size="large"
                                    border
                                >{{ cronjob.label }}</el-radio
                                >
                              </el-radio-group>
                            </el-form-item>
                            <el-form-item
                                label-width="40%"
                                label="Status"
                                prop="status"
                            >
                              <el-radio-group v-model="managerForm.status">
                                <el-radio
                                    v-for="(status, index) in [
                                      { value: 1, label: 'Activated' },
                                      { value: 0, label: 'Deactivate' },
                                    ]"
                                    :key="index"
                                    :label="status.value"
                                    size="large"
                                    border
                                >{{ status.label }}</el-radio
                                >
                              </el-radio-group>
                            </el-form-item>
                          </div>
                        </div>

                        <el-button
                            type="success"
                            class="px-5 d-block my-4 mx-auto right bottom"
                            :loading="isLoading"
                            @click="submitManagerForm(managerFormRef)"
                        >
                          Save
                        </el-button>
                      </el-form>
                    </div>
                  </el-tab-pane>

                  <el-tab-pane name="payment_configurations">
                    <template #label>
                      <span class="custom-tabs-label">
                        <i class="fa fa-medkit p-3" aria-hidden="true"></i>
                        <span>Payments Configuration</span>
                      </span>
                    </template>
                    <div class="px-1">
                      <p class="text-uppercase text-sm">UPDATE PAYMENTS CREDENTIALS</p>
                      <hr class="horizontal dark" />
                      <el-form
                          ref="mpesaFormRef"
                          :model="mpesaForm"
                          label-width="auto"
                          class="demo-ruleForm"
                          size="large"
                          status-icon
                          :scroll-to-error="true"
                          label-position="top"
                          :hide-required-asterisk="true"
                      >
                        <div class="row">
                          <div class="col-md-6">
                            <el-form-item
                                label="Client ID"
                                prop="manager_name"
                            >
                              <el-input v-model="mpesaForm.client_id" />
                            </el-form-item>
                            <el-form-item
                                label="Pass Key"
                                prop="pass_key"
                            >
                              <el-input v-model="mpesaForm.pass_key" />
                            </el-form-item>
                            <el-form-item label="Shortcode" prop="shortcode">
                              <el-input v-model="mpesaForm.shortcode" />
                            </el-form-item>
                          </div>
                          <div class="col-md-6">
                            <el-form-item label="Consumer Secret" prop="consumer_secret">
                              <el-input v-model="mpesaForm.consumer_secret" />
                            </el-form-item>
                            <el-form-item
                                label="Consumer Key"
                                prop="consumer_key"
                            >
                              <el-input v-model="mpesaForm.consumer_key" />
                            </el-form-item>
                            <el-form-item
                                label-width="40%"
                                label="STK PUSH"
                                prop="stk_push"
                            >
                              <el-radio-group v-model="mpesaForm.stk_push">
                                <el-radio
                                    v-for="(status, index) in [
                                      { value: 1, label: 'Activated' },
                                      { value: 0, label: 'Deactivate' },
                                    ]"
                                    :key="index"
                                    :label="status.value"
                                    size="large"
                                    border
                                >{{ status.label }}</el-radio
                                >
                              </el-radio-group>
                            </el-form-item>
                          </div>
                        </div>
                        <el-button
                            type="success"
                            class="px-5 d-block my-4 mx-auto right bottom"
                            :loading="isLoading"
                            @click="submitMpesaForm(mpesaFormRef)"
                        >
                          Save
                        </el-button>
                      </el-form>
                    </div>
                  </el-tab-pane>

                  <el-tab-pane name="sms_configurations">
                    <template #label>
                      <span class="custom-tabs-label">
                        <i class="fa fa-commenting p-3" aria-hidden="true"></i>
                        <span>Sms Configuration</span>
                      </span>
                    </template>
                    <div class="px-1">
                      <p class="text-uppercase text-sm">UPDATE SMS CREDENTIALS</p>
                      <hr class="horizontal dark" />
                      <el-form
                          ref="smsFormRef"
                          :model="smsForm"
                          label-width="auto"
                          class="demo-ruleForm"
                          size="large"
                          status-icon
                          :scroll-to-error="true"
                          label-position="top"
                          :hide-required-asterisk="true"
                      >
                        <div class="row">
                          <div class="col-md-6">
                            <el-form-item
                                label="Sender ID"
                                prop="sender_id"
                            >
                              <el-input v-model="smsForm.sender_id" />
                            </el-form-item>
                          </div>
                          <div class="col-md-6">
                            <el-form-item label="Api Key" prop="api_key">
                              <el-input v-model="smsForm.api_key" />
                            </el-form-item>
                          </div>
                        </div>

                        <el-button
                            type="success"
                            class="px-5 d-block my-4 mx-auto right bottom"
                            :loading="isLoading"
                            @click.prevent="submitSmsForm(smsFormRef)"
                        >
                          Save
                        </el-button>
                      </el-form>
                    </div>
                  </el-tab-pane>

                  <el-tab-pane name="role">
                    <template #label>
                      <span class="custom-tabs-label">
                        <i class="fa fa-superpowers p-3" aria-hidden="true"></i>
                        <span>Managers Roles</span>
                      </span>
                    </template>
                    <CardSpinner v-if="loader"/>
                    <RolesForm
                        v-else
                        :role="role"
                        :managerId="managerId"
                    />
                  </el-tab-pane>

                  <el-tab-pane name="properties">
                    <template #label>
                      <span class="custom-tabs-label">
                        <i class="fa fa-home p-3" aria-hidden="true"></i>
                        <span>Properties</span>
                      </span>
                    </template>
                    <CardSpinner v-if="loader"/>
                    <properties-table
                        v-else
                        :properties="properties"
                        :refresh="fetchProperties"
                    />
                  </el-tab-pane>

                  <el-tab-pane name="users">
                    <template #label>
                      <span class="custom-tabs-label">
                        <i class="fa fa-paperclip p-3" aria-hidden="true"></i>
                        <span> Manager Users </span>
                      </span>
                    </template>
                    <CardSpinner v-if="loader"/>
                    <manerial-users-table
                        v-else
                        :managerialUsers="users"
                        :refresh="fetchUsers"
                    />
                  </el-tab-pane>

                  <el-tab-pane name="invoices">
                    <template #label>
                      <span class="custom-tabs-label">
                        <i class="fa fa-sticky-note-o p-3" aria-hidden="true"></i>
                        <span> Invoices </span>
                      </span>
                    </template>
                    <CardSpinner v-if="loader"/>
                    <invoices-table
                        v-else
                        :invoices="invoices"
                        :refresh="fetchInvoices"
                    />
                  </el-tab-pane>
                </el-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import RolesForm from "../../property-manager/RolesForm.vue";
import Loader from "@/components/Loader.vue";
import CardSpinner from "../../Spinners/CardSpinner.vue";
import { ElNotification } from "element-plus";
import {reactive, onMounted, ref, watch} from "vue";
import { useRoute } from "vue-router";
import {
  fetchManagerUsers,
  fetchManagerProperties,
  fetchPropertyManagerDetails,
  updatePropertyManager,
  updatePropertyManagerMpesaCredentials,
  fetchManagerInvoices,
  fetchManagerRoles,
  smsParam
} from "../../../api/admin/managers";
import moment from "moment/moment";
import PropertiesTable from "../property-manager/PropertiesTable.vue";
import ManerialUsersTable from "../property-manager/ManerialUsersTable.vue";
import InvoicesTable from "../property-manager/InvoicesTable.vue";

const managerFormRef = ref([]);
const smsFormRef = ref([]);
const mpesaFormRef = ref([]);
const isLoading = ref(false);
const loader = ref(false);
const activeName = ref("manager");
const properties = ref({})
const invoices = ref({})
const role = ref({})
const users = ref({})

export default {

  name: "property-manager-details",
  components: {InvoicesTable, ManerialUsersTable, PropertiesTable,  Loader, CardSpinner, RolesForm },

  setup() {

    const route = useRoute();
    const managerId = route.params.id
    const managerForm = reactive({
      manager_billing_rate: "",
      cronjob: "",
      status: "",
      manager_name: "",
      manager_email: "",
      manager_address: "",
      client_id: "",
      manager_phone: "",
      invoice_vat: "",
      date_created: "",
      last_seen: "",
      login_entries_current_month: "",
      login_entries_previous_month: "",
    });

    const smsForm = reactive({
      api_key: "",
      sender_id: "",
    });

    const mpesaForm = reactive({
      shortcode: "",
      consumer_secret: "",
      consumer_key: "",
      stk_push: "",
      pass_key: "",
      client_id: "",
    });


    watch(activeName, async (newTab, oldTab) => {
      console.log("Active tab changed from", oldTab, "to", newTab);
      if (newTab === "units") {
        await fetchPropertyManagerInfo();
      } else if (newTab === "properties"){
        await fetchProperties()
      } else if (newTab === "users"){
        await fetchUsers()
      } else if (newTab === "invoices"){
        await fetchInvoices()
      } else if (newTab === "role"){
        await fetchRoles()
      }
    });

    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY HH:mm");
    };

    const fetchRoles = async () => {
      console.log('role')
      loader.value = true;
      try {
        const response = await fetchManagerRoles(route.params.id);
        role.value = response.data;
        console.log(role.value)
      } catch (error) {
        console.log(error);
      } finally {
        loader.value = false;
      }
    }

    const fetchProperties = async (filter, page) => {
      let entity_params = {};
      loader.value = true;
      try {
        if (filter === undefined) {
          entity_params = ref({});
        } else {
          entity_params = filter;
        }
        const response = await fetchManagerProperties(entity_params, page, route.params.id);
        properties.value = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        loader.value = false;
      }
    }

    const fetchUsers = async () => {
      loader.value = true;
      try {
        const response = await fetchManagerUsers(route.params.id);
        users.value = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        loader.value = false;
      }
    }

    const fetchInvoices = async (filter, page) => {
      let entity_params = {};
      loader.value = true;
      try {
        if (filter === undefined) {
          entity_params = ref({});
        } else {
          entity_params = filter;
        }
        const response = await fetchManagerInvoices(entity_params, page, route.params.id);
        invoices.value = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        loader.value = false;
      }
    }
    const fetchPropertyManagerInfo = async () => {
      isLoading.value = true;
      try {
        const response = await fetchPropertyManagerDetails(route.params.id);

        managerForm.manager_name = response.data.name
        managerForm.manager_email = response.data.email
        managerForm.manager_phone = response.data.phone
        managerForm.client_id = response.data.client_id
        managerForm.date_created = response.data.created_on
        managerForm.manager_address = response.data.phy_add
        managerForm.manager_billing_rate = response.data.billing_rate
        managerForm.status = response.data.status
        managerForm.status = response.data.status
        managerForm.last_seen = response.data.last_seen
        managerForm.login_entries_current_month = response.data.login_entries_current_month
        managerForm.login_entries_previous_month = response.data.login_entries_previous_month

        if (response.data.bouquet_parameters) {
          response.data.bouquet_parameters.forEach(param => {
            if (param.parameter === 'apiKey') {
              smsForm.api_key = param.value;
            }

            if (param.parameter === 'senderId') {
              smsForm.sender_id = param.value;
            }
          });
        }

        if (response.data.bouquet_parameters) {
          response.data.bouquet_parameters.forEach(param => {
            if (param.parameter === 'apiKey') {
              smsForm.api_key = param.value;
            }

            if (param.parameter === 'senderId') {
              smsForm.sender_id = param.value;
            }
          });
        }

        if (response.data.mpesa) {
          mpesaForm.client_id = response.data.mpesa[0].client_id
          response.data.mpesa.forEach(mpesaParam => {
            switch (mpesaParam.key) {
              case 'ShortCode':
                mpesaForm.shortcode = mpesaParam.value;
                break;
              case 'consumersecret':
                mpesaForm.consumer_secret = mpesaParam.value;
                break;
              case 'consumerkey':
                mpesaForm.consumer_key = mpesaParam.value;
                break;
              case 'passKey':
                mpesaForm.pass_key = mpesaParam.value;
                break;
              default:
                break;
            }
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    const submitMpesaForm = async (formEl) => {
      if (!formEl) return;
      const formData = new FormData();
      formData.append("shortcode", mpesaForm.shortcode);
      formData.append("consumer_secret", mpesaForm.consumer_secret);
      formData.append("consumer_key", mpesaForm.consumer_key);
      formData.append("pass_key", mpesaForm.pass_key);
      formData.append("client_id", mpesaForm.client_id);
      formData.append("stk_push", mpesaForm.stk_push);

      updatePropertyManagerMpesaCredentials(route.params.id, formData)
          .then((data) => {
            if (data.success) {
              ElNotification({
                title: "Success",
                message: data.message,
                type: "success",
              });
            } else {
              Object.values(data.errors).forEach((val) =>
                  ElNotification({
                    title: "Error",
                    message: val[0],
                    type: "error",
                  })
              );
            }
          })
          .catch((err) =>
              ElNotification({
                title: "Error",
                message: err.message,
                type: "error",
              })
          )
          .finally(() => {});
    }
    const submitManagerForm = async (formEl) => {
      if (!formEl) return;
      const formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("name", managerForm.manager_name);
      formData.append("email", managerForm.manager_email);
      formData.append("phone", managerForm.manager_phone);
      formData.append("phy_add", managerForm.manager_address);
      formData.append("billing_rate", managerForm.manager_billing_rate);
      formData.append("status", managerForm.status);

      updatePropertyManager(route.params.id, formData)
          .then((data) => {
            if (data.success) {
              ElNotification({
                title: "Success",
                message: data.message,
                type: "success",
              });
            } else {
              Object.values(data.errors).forEach((val) =>
                  ElNotification({
                    title: "Error",
                    message: val[0],
                    type: "error",
                  })
              );
            }
          })
          .catch((err) =>
              ElNotification({
                title: "Error",
                message: err.message,
                type: "error",
              })
          )
          .finally(() => {});
    };

    const submitSmsForm = async (formEl) => {
            if (!formEl) return;
            await formEl.validate((valid, fields) => {
                if (valid) {
                isLoading.value = true
                const formData = new FormData();
                formData.append("api_key", smsForm.api_key);
                formData.append("sender_id", smsForm.sender_id);
                formData.append("manager_id", managerId);

          smsParam(formData)
              .then((response) => {
                console.log(response.data);
                if (response.data.success) {
                  ElNotification({
                    title: "Success",
                    message: response.data.message,
                    type: "success",
                  });
                }else if (response.data.error) {
                  ElNotification({
                    title: 'Error',
                    message: response.data.error,
                    type: 'error',
                  });
              }  else {
                  Object.values(response.data.errors).forEach((val) =>
                      ElNotification({
                        title: "Error",
                        message: val[0],
                        type: "error",
                      })
                  );
                }
              })
              .catch((err) =>
                  console.log(err),
              )
              .finally(() => {
                isLoading.value = false
          });
              } else {
                console.log("error submit!", fields);
              }
            });
          };

    onMounted(() => {
      fetchPropertyManagerInfo();
    });

    return {
      fetchProperties,
      dateTime,
      managerFormRef,
      smsFormRef,
      mpesaFormRef,
      smsForm,
      mpesaForm,
      managerForm,
      submitManagerForm,
      submitMpesaForm,
      isLoading,
      loader,
      activeName,
      properties,
      users,
      fetchUsers,
      invoices,
      role,
      managerId,
      fetchInvoices,
      submitSmsForm
    };
  },
};
</script>
<style scoped>
.demo-image__error .image-slot {
  font-size: 30px;
}
.demo-image__error .image-slot .el-icon {
  font-size: 30px;
}
.demo-image__error .el-image {
  width: 100%;
  height: 200px;
}
.main_card {
  min-height: 90vh;
}
dt {
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 1.4rem;
  color: #495057;
  margin-bottom: 0.5rem;
}
dl {
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 20px 0;
  padding: 0 16px;
}
dd {
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.4rem;
  color: #495057;
}
.sidebar dl {
  display: grid;
  grid-template-columns: 60% 40%;
  margin: 30px 0 10px;
}
.contant-info dl {
  display: grid;
  grid-template-columns: 30% 70%;
}
.side-glance {
  max-height: 135px;
  width: 100%;
  object-fit: cover;
}
.sidebar button {
  margin: 20px auto;
  display: block;
}

.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
</style>
