<template>
  <div class="py-4 container-fluid">
    <div v-if="isLoading"><Loader /></div>
    <div class="card">
      <el-page-header :style="{ color: 'black', padding: '10px' }" @back="goBack">
        <template #icon>
          <i class="fa fa-arrow-left" aria-hidden="true" style="font-size: 15px;"></i>
        </template>
      </el-page-header>
      <div class="col-md-12 mb-2">
        <div class="card-body">
          <p class="text-uppercase text-xs">Lease Information</p>
          <div class="row">
            <div class="col-md-6 with-vertical-line">
              <dl>
                <dt class="text-uppercase text-xs">Tenant Name</dt>
                <dd class="text-xs">{{ capitalized(closedLease.tenantId) }}</dd>
                <dt class="text-uppercase text-xs">Tenant Email</dt>
                <dd class="text-xs">{{ capitalized(closedLease.email) }}</dd>
              </dl>
            </div>
            <div class="col-md-6">
              <dl>
                <dt class="text-uppercase text-xs">Unit Code</dt>
                <dd class="text-xs">{{ capitalized(closedLease.unitCode) }}</dd>
                <dt class="text-uppercase text-xs">Date Vacated</dt>
                <dd class="text-xs">{{ dateTime(closedLease.created_at) }}</dd>
                <dt class="text-uppercase text-xs">Comment</dt>
                <dd class="text-xs">{{ capitalized(closedLease.comments) }}</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body pb-2 mt-0">
        <section class="card-area">
          <el-tabs v-model="activeName" class="demo-tabs" @tab-click.native="handleClick">
            <el-tab-pane name="payments">
                <template #label>
                  <i class="fa fa-money" aria-hidden="true"></i> Payments
                </template>
                <closed-lease-payments-table
                  :payments="closedLeasePayments"
                  :refresh="fetchPaymentsData"
                />
            </el-tab-pane>
            <el-tab-pane name="deposits">
              <template #label>
                <i class="fa fa-dot-circle-o" aria-hidden="true"></i> Deposits
              </template>
              <closed-lease-deposits-table
                :deposits="closedLeaseDeposits"
                :searchQuery="searchTerm"
              />
            </el-tab-pane>
            <el-tab-pane name="arrears">
              <template #label>
                <i class="fa fa-snowflake-o" aria-hidden="true"></i> Arrears
              </template>
              <closed-lease-arrears-table
                :arrears="closedLeaseArrears"
                :searchQuery="searchTerm"
              />
            </el-tab-pane>
            <el-tab-pane name="water_bills">
              <template #label>
                <i class="fa fa-tint" aria-hidden="true"></i> Water Bills
              </template>
              <closed-lease-water-bills-table
                :water="closedLeaseWaterBills"
                :searchQuery="searchTerm"
              />
            </el-tab-pane>
            <el-tab-pane name="penalties">
              <template #label>
                <i class="fa fa-times" aria-hidden="true"></i> Penalties
              </template>
              <closed-lease-penalties-table
                  :penalties="closedLeasePenalties"
                  :searchQuery="searchTerm"
              />
            </el-tab-pane>
            <el-tab-pane name="unit_charges">
              <template #label>
                <i class="fa fa-bolt" aria-hidden="true"></i> Unit Charges
              </template>
              <closed-lease-unit-charges-table
                  :charges="closedLeaseCharges"
                  :searchQuery="searchTerm"
              />
            </el-tab-pane>
          </el-tabs>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import ClosedLeasePaymentsTable from "./../components/property-manager/ClosedLeasePaymentsTable.vue";
import ClosedLeaseDepositsTable from "./../components/property-manager/ClosedLeaseDepositsTable.vue";
import ClosedLeaseArrearsTable from "./../components/property-manager/ClosedLeaseArrearsTable.vue";
import ClosedLeaseWaterBillsTable from "./../components/property-manager/ClosedLeaseWaterBillsTable.vue";
import ClosedLeasePenaltiesTable from "./../components/property-manager/ClosedLeasePenaltiesTable.vue";
import ClosedLeaseUnitChargesTable from "@/views/components/property-manager/ClosedLeaseUnitChargesTable.vue";
import Loader from "@/views/components/Loader.vue";
import router from "@/router";
import {
  fetchClosedLeaseArrears,
  fetchClosedLeaseDeposits,
  fetchClosedLeasePayments,
  fetchClosedLeasePenalties,
  fetchClosedLeaseUnitCharges,
  fetchClosedLeaseWaterBills,
  fetchSingleClosedLease
} from "@/api/property-manager/closedLeases";
import moment from "moment";


const isLoading = ref(false);
const closedLeaseId = ref(null);
const closedLease = ref([]);
const closedLeasePayments = ref([]);
const closedLeaseDeposits = ref([]);
const closedLeaseArrears = ref([]);
const closedLeaseWaterBills = ref([]);
const closedLeaseCharges = ref([]);
const closedLeasePenalties = ref([]);
const activeName = ref('payments');
const searchTerm = ref("");

export default {
  name: "closed-leases",
  components: {
    Loader,
    ClosedLeaseDepositsTable,
    ClosedLeasePaymentsTable,
    ClosedLeaseArrearsTable,
    ClosedLeaseWaterBillsTable,
    ClosedLeasePenaltiesTable,
    ClosedLeaseUnitChargesTable
  },
  props: {
    property: {
      type: Object,
      required: false,
    },
  },
  setup() {
    const handleClick = () => {}
    const fetchClosedLeaseData = async () => {
      try {
        isLoading.value = true;
        const response = await fetchSingleClosedLease(closedLeaseId.value);
        closedLease.value = response.data;
        console.log(closedLease.value)
      } catch (error) {
        console.log("Error fetching payments:", error);
      } finally {
        isLoading.value = false;
      }
    };

    const fetchPaymentsData = async (search, page) => {
      searchTerm.value = search;
      console.log(closedLeaseId.value)
      try {
        isLoading.value = true;
        const response = await fetchClosedLeasePayments(search, page, closedLeaseId.value);
        closedLeasePayments.value = response.data;
      } catch (error) {
        console.log("Error fetching payments:", error);
      } finally {
        isLoading.value = false;
      }
    };

    const fetchDepositsData = async (search, page) => {
      searchTerm.value = search;
      try {
        isLoading.value = true;
        const response = await fetchClosedLeaseDeposits(search, page, closedLeaseId.value);
        closedLeaseDeposits.value = response.data;
      } catch (error) {
        console.log("Error fetching payments:", error);
      } finally {
        isLoading.value = false;
      }
    };

    const fetchArrearsData = async (search, page) => {
      searchTerm.value = search;
      try {
        isLoading.value = true;
        const response = await fetchClosedLeaseArrears(search, page, closedLeaseId.value);
        closedLeaseArrears.value = response.data;
      } catch (error) {
        console.log("Error fetching payments:", error);
      } finally {
        isLoading.value = false;
      }
    };
    const fetchWaterBillsData = async (search, page) => {
      searchTerm.value = search;
      try {
        isLoading.value = true;
        const response = await fetchClosedLeaseWaterBills(search, page, closedLeaseId.value);
        closedLeaseWaterBills.value = response.data;
      } catch (error) {
        console.log("Error fetching payments:", error);
      } finally {
        isLoading.value = false;
      }
    };
    const fetchPenaltiesData = async (search, page) => {
      searchTerm.value = search;
      try {
        isLoading.value = true;
        const response = await fetchClosedLeasePenalties(search, page, closedLeaseId.value);
        closedLeasePenalties.value = response.data;
      } catch (error) {
        console.log("Error fetching payments:", error);
      } finally {
        isLoading.value = false;
      }
    };
    const fetchUnitChargesData = async (search, page) => {
      searchTerm.value = search;
      try {
        isLoading.value = true;
        const response = await fetchClosedLeaseUnitCharges(search, page, closedLeaseId.value);
        closedLeaseCharges.value = response.data;
      } catch (error) {
        console.log("Error fetching payments:", error);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      const closedLeaseIdFromRoute = router.currentRoute.value.params.closedLeaseId;
      closedLeaseId.value = closedLeaseIdFromRoute;
      fetchPaymentsData();
      fetchClosedLeaseData();
    });

    watch(activeName, async (newTab, oldTab) => {
      console.log("Active tab changed from", oldTab, "to", newTab);
      if (newTab === "payments") {
        fetchPaymentsData();
      } else if (newTab === "deposits"){
        fetchDepositsData();
      } else if (newTab === "arrears"){
        fetchArrearsData();
      } else if (newTab === "water_bills"){
        fetchWaterBillsData();
      } else if (newTab === "penalties"){
        fetchPenaltiesData();
      } else if (newTab === "unit_charges"){
        fetchUnitChargesData();
      }
    });

    const capitalized = (name) => {
      const capitalizedFirst = name[0].toUpperCase();
      const rest = name.slice(1);
      return capitalizedFirst + rest;
    };

    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };
    const goBack = () => {
      router.push({ path: "/closed-leases" });
    }

    return {
      goBack,
      dateTime,
      capitalized,
      isLoading,
      searchTerm,
      activeName,
      closedLease,
      handleClick,
      closedLeasePayments,
      closedLeaseDeposits,
      closedLeaseArrears,
      closedLeaseWaterBills,
      closedLeaseCharges,
      closedLeasePenalties,
      fetchPaymentsData
    };
  },
};
</script>

<style scoped>

h6 {
  margin: 0;
}
</style>
<style scoped>

.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}


ol {
  list-style: none;
  padding: 1rem 0;
}

ol li:before {
  content: "✓";
  color: green;
}
</style>
