<template>
  <div class="card">
    <div class="card-header pb-0 mb-5">
      <div class="d-flex align-items-center gap-2">
        <i class="fa fa-paper-plane text-primary text-md opacity-10"></i>
        <h6>Closed Leases</h6>
      </div>
      <div class="cta">
        <FilterTablesForm
            :show_closed_leases_table_filter="true"
            :filter_init="filter_params"
            :filterCallBack="_filterCallBack"
        />
       </div>
    </div>
    <hr class="horizontal dark" />
    <div class="px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <div class="card-table">
          <el-table
              ref="multipleTableRef"
              :data="tableData"
              style="width: 100%; position: relative;"
              @selection-change="handleSelectionChange"
              class="sticky-top"
          >
            <el-table-column type="selection" width="55" />
            <el-table-column prop="tenant.tenantId" label="Tenant Name" width="300" />
            <el-table-column prop="tenant.email" label="Email" show-overflow-tooltip />
            <el-table-column prop="tenant.unitCode" label="Unit Code" show-overflow-tooltip />
            <el-table-column prop="tenant.comments" label="Closing Comment" show-overflow-tooltip />
            <el-table-column prop="tenant.createdAtFromSourceSys" label="Date Closed" show-overflow-tooltip />
            <el-table-column label="Actions">
              <template #default="scope">
                <el-button v-if="hasPermission('view.leases')" type="success" @click="handleView(scope.row)" style="display: block;">View</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="d-flex justify-content-center mt-5">
          <pagination
              :totalPages="Pagination.total_pages"
              :perPage="Pagination.per_page"
              :currentPage="Pagination.current_page"
              @pagechanged="onPageChange"
          />
        </div>

        <div class="card--footer" style="margin-top: 0.2px; margin-bottom: 80px">
          <el-button
              type="primary"
              @click="toggleSelection()"
          >Clear selection</el-button>
          <el-button
              v-if="hasPermission('delete.leases')"
              type="danger"
              :loading="isLoading"
              @click="submitChecked()"
          >Delete</el-button>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import {reactive, ref, toRefs} from 'vue';
import moment from "moment/moment";
import { ElNotification } from "element-plus";
import { deleteClosedLeases } from "@/api/property-manager/closedLeases";
import Pagination from "@/views/components/property-manager/Pagination.vue";
import { useRouter } from "vue-router";
import FilterTablesForm from "./FilterTablesForm.vue";

const filter_params = ref({});
const tableData = ref([]);
const property = ref([]);
const isLoading = ref(false);


export default {
  components: {Pagination, FilterTablesForm},
  props: {
    closedLeases: {
      type: Object,
      required: true,
    },
    refresh: {
      type: Function,
      required: true,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();

    const total_records = props?.closedLeases?.total || 0;
    const per_page = props?.closedLeases?.per_page || 0;
    const total_pages = ref(0);
    if (per_page > 0 && total_records > 0) {
      total_pages.value = Math.ceil(total_records / per_page);
    }

    const Pagination = reactive({
      current_page: props?.closedLeases?.current_page || 1,
      total_pages: total_pages,
      per_page: per_page,
    });

    const onPageChange = (page) => {
      props.refresh(Filter.searchquery, page);
    };
    const Filter = reactive({
      searchquery: props.searchQuery != null ? props.searchQuery : "",
    });

    const { closedLeases } = toRefs(props);
    const multipleTableRef = ref();
    const multipleSelection = ref([]);

    const toggleSelection = (rows) => {
      if (rows) {
        rows.forEach((row) => {
          multipleTableRef.value?.toggleRowSelection(row, undefined);
        });
      } else {
        console.log(multipleSelection.value)
        multipleTableRef.value?.clearSelection();
      }
    };

    const handleSelectionChange = (val) => {
      multipleSelection.value = val.map(item => {
        return {
          ...item,
          leaseId: item.tenant.leaseId
        };
      });
    };
    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };

    const handleView = (row) => {
      let closedLeaseId = row.id;
      router.push(`/closed-lease/${closedLeaseId}`);
    };

        tableData.value = closedLeases.value.data.map(tenant => {
          console.log(tenant.tenantId)
          return {
            ...tenant,
            tenant: {
              leaseId: tenant.closeCode,
              tenantId: tenant.tenantId,
              email: tenant.email,
              unitCode: tenant.unitCode,
              comments: tenant.comments,
              createdAtFromSourceSys: dateTime(tenant.created_at)
            },
          };
        });

    const capitalized = (name) => {
      const capitalizedFirst = name[0].toUpperCase();
      const rest = name.slice(1);
      return capitalizedFirst + rest;
    };

  

    const submitChecked = () => {
      const selectedLeaseIds = multipleSelection.value.map(item => item.leaseId);
      isLoading.value = true;
      const formData = new FormData();
      formData.append("leaseId", selectedLeaseIds);
      deleteClosedLeases(formData)
          .then((data) => {
            if (data.success) {
              ElNotification({
                title: "Success",
                message: data.message,
                type: "success",
              });
              emit("refresh");
            } else {
              Object.values(data.errors).forEach((val) =>
                  ElNotification({
                    title: "Error",
                    message: val[0],
                    type: "error",
                  })
              );
            }
          })
          .catch((err) =>
              ElNotification({
                title: "Error",
                message: err.message,
                type: "error",
              })
          )
          .finally(() => {
            multipleTableRef.value?.clearSelection();
            isLoading.value = false;
            emit("refresh");
          });
    };
    const _filterCallBack = (filter_data) => {
      filter_params.value = filter_data;
      props.refresh(filter_params, 0);
    };

    return {
      Pagination,
      onPageChange,
      isLoading,
      multipleTableRef,
      multipleSelection,
      toggleSelection,
      handleSelectionChange,
      handleView,
      tableData,
      property,
      capitalized,
      dateTime,
      submitChecked,
      _filterCallBack,
      filter_params
    };
  },
  data(){ 
    const storedPermissions = JSON.parse(localStorage.getItem("permissionlist"));
    return {
      userPermissions: storedPermissions || [],

    };
  },
  methods: {
    hasPermission(slug) {
      return this.userPermissions.includes(slug);
    },
  },
};
</script>

<style scoped>

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
h6 {
  margin: 0;
}
.card-table {
    max-height: 65vh;
    overflow-x: auto;
    overflow-y: auto;
  }
.sticky-top {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #5f924d;
}
.sticky-top th {
  color: #fff !important;
}
.card--footer {
  display: flex;
  justify-content: flex-end;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
</style>
