<template>
  <div class="modal-overlay" @click="$emit('close-modal')">
    <div class="card" @click.stop>
      <div class="card-header">
        <i class="fa fa-times-circle text-primary me-2" aria-hidden="true"></i>Close Lease</div>
      <hr class="horizontal dark m-0" />

      <el-form
          ref="leaseFormRef"
          :model="leaseForm"
          :rules="rules"
          label-width="120px"
          size=""
          status-icon
          :scroll-to-error="true"
          label-position="top"
          :hide-required-asterisk="true"
      >
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 mb-3">
              <el-alert
                  v-if=" props?.unit?.leases[0].accounts[0].balance < 0"
                  title="warning alert !"
                  type="error"
                  description="The tenant should have 0 arrears."
                  show-icon
              />
            </div>
            <div class="col-md-12 mb-3">
              <el-form-item label="Tenant Name" prop="tenantName">
                <el-input v-model="leaseForm.tenantName" />
              </el-form-item>
            </div>
            <div class="col-md-12 mb-3">
              <el-form-item label="Unit Code" prop="unitCode">
                <el-input v-model="leaseForm.unitCode" />
              </el-form-item>
            </div>

            <div class="col-md-12 mb-3">
              <el-form-item label="Description" prop="description">
                <el-input
                    v-model="leaseForm.description"
                    autosize
                    type="textarea"
                    placeholder="Please input description"
                />
              </el-form-item>
            </div>

            <div class="col-md-12 mb-3">
              <el-form-item label="Date" prop="date">
                <el-date-picker
                    style="width: 100%"
                    v-model="leaseForm.date"
                    type="date"
                    placeholder="Select Date"
                    :size="size"
                />
              </el-form-item>
            </div>

          </div>
        </div>
        <div class="card--footer">
          <button
              class="btn mb-0 btn-danger btn-xs null null"
              @click="$emit('close-modal')"
          >
            Cancel
          </button>
          <el-button
              type="success"
              :loading="isLoading"
              :disabled="isButtonDisabled"
              @click="submitForm(leaseFormRef)"
          >Close</el-button
          >
        </div>
      </el-form>
    </div>
    <div class="close" @click="$emit('close-modal')">
      <i class="fa fa-times" />
    </div>
  </div>
</template>

<script>
import { reactive, ref, computed } from "vue";
import { closeLease } from "@/api/property-manager/leases";
import { ElNotification } from "element-plus";
import router from "@/router";

const value2 = ref('')
const leaseFormRef = ref();
const isLoading = ref(false);
const propertyManagerId = localStorage.getItem("propertyManagerId");

export default {
  name: "lease-form",

  props: {
    unit: {
      type: Object,
      required: false,
    },
    refresh: {
      type: Function,
      required: true,
    },
  },

  setup(props, { emit }) {
    const leaseForm = reactive({
      tenantName: props?.unit?.leases[0].tenant.firstName + " " + props?.unit?.leases[0].tenant.lastName || "",
      unitCode: props?.unit?.unitCode || "",
      description: "",
      date: "",
    });

    const rules = reactive({
      tenantName: [
        {
          required: true,
          message: "Please select tenant",
          trigger: ["blur", "change"],
        },
      ],
      unitCode: [
        {
          required: true,
          message: "Please input unit code",
          trigger: ["blur", "change"],
        },
      ],
      description: [
        {
          required: true,
          message: "Please input description",
          trigger: ["blur", "change"],
        },
      ],

      date: [
        {
          required: true,
          message: "Please select date",
          trigger: ["blur", "change"],
        },
      ]
    });

    const dateTransformer = (dateString) => {
      var date = new Date(dateString);

      var year = date.getFullYear();
      var month = ("0" + (date.getMonth() + 1)).slice(-2);
      var day = ("0" + date.getDate()).slice(-2);


      return year + "-" + month + "-" + day;
    }
    const submitForm = async (formEl) => {
      console.log(props.unit);
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true;
          const formData = new FormData();
          formData.append("unitId", props?.unit?.leases[0].unitId);
          formData.append("date", dateTransformer(leaseForm.date));
          formData.append("description", leaseForm.description);
          formData.append("tenantId", props?.unit?.leases[0].tenantId);
          formData.append("leaseId", props?.unit?.leases[0].leaseId);
          formData.append("managerId", propertyManagerId);

          closeLease(formData)
              .then((data) => {
                if (data.success) {
                  ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                  });
                  emit("refresh");
                  emit("close-modal");
                  router.push({ path: "/closed-leases" });
                } else {
                  Object.values(data.errors).forEach((val) =>
                      ElNotification({
                        title: "Error",
                        message: val[0],
                        type: "error",
                      })
                  );
                }
              })
              .catch((err) =>
                  console.log(err),

              )
              .finally(() => {
                isLoading.value = false;
              });
        } else {
          console.log("error submit!", fields);
        }
      });
    };

    const userBalance = ref(props?.unit?.leases[0].accounts[0].balance);

    const isButtonDisabled = computed(() => {
      return userBalance.value < 0;
    });

    return {
      leaseForm,
      submitForm,
      rules,
      leaseFormRef,
      isLoading,
      props,
      isButtonDisabled,
      value2
    };
  },
};
</script>
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.card {
  margin-top: 5%;
  outline: 0;
  border-radius: 5px;
  max-width: 630px;
  max-height: 600px;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
}
.card-body {
  max-height: 480px;
  overflow-x: hidden;
  overflow-y: auto;
}

.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}
.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  background-color: #f4443e;
  color: #ffffff;
  border: none;
  height: 30px;
  width: 30px;
  opacity: .75;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .3s ease;
}

.close i {
  font-size: 15px;
}
input {
  border-radius: 6px;
}
</style>
